<template>
  <div class="m_questions">
    <div class="item">
      <div class="top">
        <div class="title">{{ testInfo.courseName }}</div>
      </div>
      <template v-if="type == 0">
        <div class="progress">
          <van-progress
            :stroke-width="6"
            :show-pivot="false"
            track-color="rgba(216, 216, 216, 0.4)"
            color="#BA2926"
            :percentage="progress || 0"
          />
        </div>
        <div class="bottom">
          <div>{{ complete }}/{{ testInfo.test_titleList.length }}</div>
          <div style="margin-left: 5px">考试中</div>
        </div>
      </template>
    </div>
    <div class="item">
      <!--      <div class="m_questions_type">单选题</div>-->
      <div
        class="m_questions_box"
        v-for="(item, index) in testInfo.test_titleList"
        :key="index"
        :ref="`stem${index}`"
      >
        <div class="m_questions_box_title">
          {{ index + 1 + "、" + item.question }}
        </div>
        <div class="m_questions_box_option">
          <div
            class="m_questions_box_option_item"
            :class="{
              active: items.active && type == 0,
              'check-true':
                (items.isTrue == 2 && type == 1) || (items.isTrue && type == 2),
              'check-false': items.isTrue == 1 && type == 1,
            }"
            v-for="(items, indexs) in item.jsonOptions"
            :key="indexs"
            @click="selectClick(items, item.jsonOptions, item.questionType)"
          >
            {{ items.options + ". " + items.answer }}
          </div>
        </div>
        <div class="m_questions_box_details" v-if="type == 1">
          <div class="details_title">
            <span class="details_state" :class="{ danger: !item.ifRight }">{{
              item.ifRight ? "回答正确" : "回答错误"
            }}</span>
            <span
              >您的答案: <em>{{ getAnswer(item) }}</em></span
            >
          </div>
        </div>
      </div>
    </div>
    <div style="height: 56px">
      <div class="m_footer">
        <div class="switch-btn" @click="isShow = true">
          <img src="@/assets/icon/problem-icon.png" alt="" />
          选题
        </div>
        <template v-if="type == 0">
          <div class="m_footer_btn cancel-btn" @click="handlerBack">
            取消考试
          </div>
          <div class="m_footer_btn submit-btn" @click="submitClick">
            提交试卷
          </div>
        </template>
        <template v-else>
          <div class="m_footer_btn submit-btn" @click="handlerBack">返回</div>
        </template>
      </div>
    </div>
    <!--    选题弹窗-->
    <div class="m_popup_mask" @click="isShow = false" v-if="isShow">
      <div class="m_popup bottom" @click.stop="isShow = true">
        <div class="m_popup_title">
          {{ testInfo.name }}
          <van-icon @click.stop="isShow = false" name="cross" size="18" />
        </div>
        <div class="m_popup__body">
          <div
            class="question-number"
            :class="isActive(item)"
            v-for="(item, index) in testInfo.test_titleList"
            :key="index"
            @click="questionClick(item, index)"
          >
            {{ index + 1 }}
          </div>
        </div>
      </div>
    </div>
    <!--    考试结果-->
    <div class="m_popup_mask" v-if="resultShow">
      <div class="m_popup center">
        <div class="m_popup_title">
          {{ IsChengGong === 0 ? "考试未通过" : "考试通过" }}
        </div>
        <div class="m_popup_img">
          <img
            v-if="IsChengGong === 0"
            src="@/assets/images/error-img.png"
            alt=""
          />
          <img v-else src="@/assets/images/success-img.png" alt="" />
        </div>
        <div class="m_popup_text">
          您的分数为
          <span
            :style="`color: ${
              IsChengGong == 0 ? '#b50413' : '#85ce61'
            };margin-left: 5px;`"
            >{{ fenShu }}</span
          >
        </div>
        <div
          class="m_popup_text"
          style="margin-top: 0"
          v-if="IsChengGong === 0 && IsChongXue === 1"
        >
          您的3次答题机会已用完，请重新学习
        </div>
        <div class="m_popup_btn">
          <div class="btn_item" @click="handlerBack">确 定</div>
          <div class="btn-flex">
            <div
              style="margin-top: 12px"
              class="btn_item plain"
              v-if="IsChengGong === 0 && IsChongXue === 0"
              @click="handelError"
            >
              纠错查看
            </div>
            <div
              class="btn_item plain"
              v-if="IsChengGong === 0 && IsChongXue === 0"
              @click="refreshPage"
            >
              重新考试
            </div>
          </div>
          <div
            class="btn_item plain"
            v-if="
              (IsChengGong === 1 && IsChongXue === 0) ||
              (IsChengGong === 0 && IsChongXue === 1)
            "
            @click="handelAnswer"
          >
            查看答案
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {
  getErrorStudyTestTitleList,
  getStudyTestTitleList,
  getTrueStudyTestTitleList,
  postStudyTestTitleSend,
} from "@/api/specializedController";
import * as storage from "@/utils/storage";

export default {
  name: "m_questions",
  data() {
    return {
      isShow: false,
      resultShow: false,
      testInfo: {
        courseName: "",
        test_titleList: [],
      },
      courseId: 0,
      fenShu: 0,
      IsChengGong: 0,
      IsChongXue: 0,
      type: 0, // 0 考试、 1 纠错、 2 查看答案
    };
  },
  computed: {
    progress() {
      return (this.complete / this.testInfo.test_titleList.length) * 100;
    },
    complete() {
      const dataList = JSON.parse(JSON.stringify(this.testInfo.test_titleList));
      dataList.map((item) => {
        const answer = item.jsonOptions.filter(
          (items) => items.active === true
        );
        item.answer = answer
          .map((items) => {
            return items.options;
          })
          .join(",");
        delete item.questionType;
        delete item.question;
        delete item.jsonOptions;
        return item;
      });
      return dataList.filter((item) => item.answer).length;
    },
    yearId() {
      return storage.getStore("yearId");
    },
  },
  created() {
    this.courseId = this.$route.query.courseId;
    this.getTestTitleInfo();
    // this.handelError();
    // this.handelAnswer();
  },
  methods: {
    // 获取试卷
    getTestTitleInfo() {
      getStudyTestTitleList({
        courseId: this.courseId,
        trainingPlanId: this.yearId,
      }).then((res) => {
        res.data.test_titleList.map((item) => {
          item.jsonOptions = JSON.parse(item.jsonOptions).map((items) => {
            items.active = false;
            return items;
          });
          return item;
        });
        this.testInfo = res.data;
      });
    },
    // 选择答案
    selectClick(item, option = [], type = 0) {
      if (this.type != 0) {
        return false;
      }
      if (type === 0 && item.active === false) {
        option.map((items) => {
          items.active = false;
          return items;
        });
      }
      item.active = !item.active;
    },
    questionClick(item, index) {
      this.isShow = false;
      document.documentElement.scrollTop =
        this.$refs[`stem${index}`][0].offsetTop;
    },
    // 提交试卷
    submitClick() {
      const dataList = JSON.parse(JSON.stringify(this.testInfo.test_titleList));
      dataList.map((item) => {
        const answer = item.jsonOptions.filter(
          (items) => items.active === true
        );
        item.answer = answer
          .map((items) => {
            return items.options;
          })
          .join(",");
        delete item.questionType;
        delete item.question;
        delete item.jsonOptions;
        return item;
      });
      postStudyTestTitleSend({
        c3R1ZHlDb3Vyc2VJZA: this.courseId,
        YW5zd2Vy: JSON.stringify(dataList),
        trainingPlanId: this.yearId,
      }).then((res) => {
        this.fenShu = res.data.fenShu;
        this.IsChengGong = res.data.isChengGong;
        this.IsChongXue = res.data.isChongXue;
        this.resultShow = true;
      });
    },
    // 重新考试
    refreshPage() {
      this.testInfo = {
        courseName: "",
        test_titleList: [],
      };
      this.resultShow = false;
      this.type = 0;
      this.IsChengGong = 0;
      this.IsChongXue = 0;
      this.getTestTitleInfo();
    },
    // 题目纠错
    handelError() {
      this.testInfo = {
        courseName: "",
        test_titleList: [],
      };
      this.type = 1;
      this.resultShow = false;
      getErrorStudyTestTitleList({
        courseId: this.courseId,
        trainingPlanId: this.yearId,
      }).then((res) => {
        res.data.testTitleList.map((item) => {
          item.jsonOptions = JSON.parse(item.jsonOptions);
          return item;
        });
        this.testInfo = {
          courseName: res.data.courseName,
          test_titleList: res.data.testTitleList,
        };
      });
    },
    // 获取选择的答案
    getAnswer(data) {
      const item = JSON.parse(JSON.stringify(data));
      const answer = item.jsonOptions.filter((items) => items.isTrue != 0);
      return answer
        .map((item) => {
          return item.options;
        })
        .join(",");
    },
    // 查看答案
    handelAnswer() {
      this.type = 2;
      this.resultShow = false;
      this.testInfo = {
        courseName: "",
        test_titleList: [],
      };
      getTrueStudyTestTitleList({
        courseId: this.courseId,
        trainingPlanId: this.yearId,
      }).then((res) => {
        res.data.testTitleList.map((item) => {
          item.jsonOptions = JSON.parse(item.jsonOptions);
        });
        this.testInfo = {
          courseName: res.data.courseName,
          test_titleList: res.data.testTitleList,
        };
      });
    },
    isActive(item) {
      if (this.type === 0) {
        const isTrue =
          item.jsonOptions.filter((item) => item.active).length > 0;
        if (isTrue) {
          return "question-danger";
        }
      } else if (this.type === 1) {
        return item.ifRight ? "question-success" : "question-danger";
      } else {
        return "question-success";
      }
    },
  },
};
</script>
<style scoped lang="scss">
.m_questions {
  .item {
    border-radius: 8px;
    padding: 15px;
    box-sizing: border-box;
    margin-bottom: 15px;
    background: #ffffff;
    .top {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      align-content: center;
      justify-content: space-between;
      align-items: center;
      .title {
        color: #3d3d3d;
        font-size: 16px;
      }
      .more {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: flex-start;
        align-items: center;
        color: #3d3d3d;
      }
      .more-icon {
        transform: rotate(-90deg);
        color: #3d3d3d;
      }
    }
    .progress {
      width: 100%;
      margin: 12px auto;
    }
    .bottom {
      display: flex;
      font-size: 14px;
      flex-direction: row;
      flex-wrap: nowrap;
      align-content: center;
      justify-content: flex-start;
      align-items: center;
      color: #818496;
    }
    .m_questions_type {
      background: rgba(186, 41, 38, 0.2);
      border-radius: 4px;
      padding: 2px 6px;
      color: #ba2926;
      font-size: 12px;
      display: inline-block;
      margin-bottom: 8px;
    }
    .m_questions_box {
      padding-bottom: 16px;
      margin-bottom: 16px;
      border-bottom: 1px solid #ebebeb;
      &:last-child {
        border-bottom: 0;
        padding-bottom: 0;
        margin-bottom: 0;
      }
      .m_questions_box_title {
        color: #3d3d3d;
        font-size: 14px;
      }
      .m_questions_box_option {
        .m_questions_box_option_item {
          background: #f5f5f5;
          border: 1px solid #f5f5f5;
          padding: 8px;
          font-size: 14px;
          border-radius: 4px;
          margin-top: 12px;
          color: #505363;
          &:first-child {
            margin-top: 16px;
          }
          &.active {
            border: 1px solid rgba(22, 119, 254, 0.5);
            background: rgba(22, 119, 254, 0.06);
            color: #1677fe;
          }
          &.check-true {
            color: #61b261;
            background: rgba(97, 178, 97, 0.06);
            box-sizing: border-box;
            border: 1px solid rgba(97, 178, 97, 0.5);
          }
          &.check-false {
            color: #ba2926;
            background: rgba(186, 41, 38, 0.06);
            box-sizing: border-box;
            border: 1px solid rgba(186, 41, 38, 0.5);
          }
        }
      }
      .m_questions_box_details {
        margin-top: 16px;
        .details_title {
          margin-bottom: 8px;
          span {
            margin-right: 24px;
            color: #21252e;
            em {
              margin-left: 5px;
              font-style: normal;
              color: #1677fe;
            }
          }
          .details_state {
            border-radius: 3px;
            padding: 6px 10px;
            background: #61b261;
            font-size: 12px;
            color: #ffffff;
            margin-right: 24px;
            &.danger {
              background-color: #ba2926;
            }
          }
        }
        .details-body {
          padding: 12px;
          background-color: #f5f5f5;
          border-radius: 4px;
          font-size: 14px;
          .details-analysis {
            color: #818496;
          }
          .analysis-body {
            color: #21252e;
          }
        }
      }
    }
  }
  .m_footer {
    position: fixed;
    bottom: 0;
    padding: 8px 14px;
    background: #ffffff;
    width: 100%;
    height: 56px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
    border-top: 1px solid #eceef1;
    gap: 12px;
    .switch-btn {
      flex: 2;
      text-align: center;
      display: flex;
      flex-direction: column;
      align-items: center;
      color: #505363;
      font-size: 12px;
      img {
        width: 22px;
        height: 22px;
      }
    }
    .m_footer_btn {
      background: #ba2926;
      padding: 6px 16px;
      color: #ffffff;
      border-radius: 4px;
      text-align: center;
      &.cancel-btn {
        flex: 3;
        border: 1px solid #ba2926;
        background: rgba(186, 41, 38, 0.05);
        color: #ba2926;
      }
      &.submit-btn {
        flex: 5;
      }
    }
  }
  .m_popup_mask {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 9999;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    .m_popup {
      position: absolute;
      padding: 14px;
      box-sizing: border-box;
      box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.06);
      background-color: #ffffff;
      &.center {
        padding: 28px 24px;
        transform: translate(-50%, -50%);
        left: 50%;
        top: 50%;
        border-radius: 8px;
        width: 250px;
        .m_popup_title {
          text-align: center;
          margin-bottom: 16px;
          font-size: 18px;
        }
        .m_popup_text {
          margin-top: 16px;
          text-align: center;
          font-size: 14px;
        }
        .m_popup_img {
          width: 80px;
          height: 80px;
          margin: 0 auto;
          img {
            width: 100%;
            height: 100%;
          }
        }
        .m_popup_btn {
          margin-top: 24px;
          .btn_item {
            margin-top: 12px;
            background: #ba2926;
            padding: 6px 16px;
            color: #ffffff;
            border-radius: 4px;
            text-align: center;
            font-size: 14px;
            &:first-child {
              margin-top: 0;
            }
            &.plain {
              border: 1px solid #ba2926;
              background: rgba(186, 41, 38, 0.05);
              color: #ba2926;
            }
          }
          .btn-flex {
            display: flex;
            align-items: center;
            margin-top: 12px;
            gap: 12px;
            .btn_item {
              margin-top: 0 !important;
              flex: 1;
            }
          }
        }
      }
      &.bottom {
        transform: translate(0, 0);
        border-radius: 8px 8px 0 0;
        width: 100%;
        top: 50%;
        bottom: 0;
        .m_popup_title {
          padding-bottom: 10px;
          border-bottom: 1px solid #ebebeb;
          font-size: 14px;
          height: 32px;
          box-sizing: border-box;
          display: flex;
          align-items: center;
          justify-content: space-between;
        }
        .m_popup__body {
          height: calc(100% - 32px);
          overflow-y: auto;
          padding: 10px 0 1px 0;
          display: flex;
          flex-direction: row;
          gap: 12px 16px;
          flex-wrap: wrap;
          align-content: flex-start;
          align-self: stretch;
          box-sizing: border-box;
          &::-webkit-scrollbar {
            display: none;
          }
          .question-number {
            display: inline-block;
            background: #f5f5f5;
            box-sizing: border-box;
            border: 1px solid #e5e5e5;
            text-align: center;
            line-height: 38px;
            width: 38px;
            height: 38px;
            border-radius: 4px;
            &.question-success {
              background: #61b261;
              border: 1px solid #61b261;
              color: #ffffff;
            }
            &.question-warning {
              background: #e57d2b;
              color: #ffffff;
              border: 1px solid #e57d2b;
            }
            &.question-danger {
              background: #ba2926;
              color: #ffffff;
              border: 1px solid #ba2926;
            }
          }
        }
      }
    }
  }
}
</style>
